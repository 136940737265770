var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v(" Sites "),_c('CButton',{staticClass:"float-right",attrs:{"square":"","color":"info","size":"sm","variant":"outline","to":"/sites/create"}},[_vm._v(" Add New Site ")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"columnFilter":{ external: true, lazy: true },"columnFilterValue":_vm.currentFilters,"sorter":{ external: true },"sorterValue":_vm.currentSort,"loading":_vm.isLoading,"responsive":true},on:{"update:columnFilterValue":function($event){_vm.currentFilters=$event},"update:column-filter-value":[function($event){_vm.currentFilters=$event},_vm.changeFilter],"update:sorter-value":_vm.changeSort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status == 0)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v("Active")]):_vm._e(),(item.status == 1)?_c('CBadge',{attrs:{"color":"warning"}},[_vm._v("Inactive")]):_vm._e()],1)]}},{key:"status-filter",fn:function(ref){
var item = ref.item;
return [_c('CSelect',{staticClass:"table-select",attrs:{"size":"sm","options":[ { value: null, label: 'All' }, { value: 0, label: 'Active' }, { value: 1, label: 'Inactive' } ]},on:{"change":function($event){return _vm.changeStatusFilter($event)}},model:{value:(_vm.currentFilters.status),callback:function ($$v) {_vm.$set(_vm.currentFilters, "status", $$v)},expression:"currentFilters.status"}})]}},{key:"createdAt-filter",fn:function(ref){
var item = ref.item;
return [_c('div')]}},{key:"operations",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButtonGroup',[_c('CButton',{attrs:{"square":"","color":"info","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.$router.push({name: 'Edit Site', params: { id: item._id }})}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),(item.status == 1)?_c('CButton',{attrs:{"square":"","color":"success","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.opModalShow('unsuspend', item)}}},[_c('CIcon',{attrs:{"name":"cil-lock-locked"}})],1):_vm._e(),(item.status == 0)?_c('CButton',{attrs:{"square":"","color":"warning","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.opModalShow('suspend', item)}}},[_c('CIcon',{attrs:{"name":"cil-lock-locked"}})],1):_vm._e(),_c('CButton',{attrs:{"square":"","color":"danger","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.opModalShow('delete', item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)],1)]}},{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]}},[_c('CSpinner',{attrs:{"color":"secondary"}})],1)]},proxy:true},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_vm._v(" No data ")])]},proxy:true}])}),_c('CPagination',{attrs:{"align":"center","activePage":_vm.currentPage,"pages":_vm.pages},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.changePage],"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1),_c('CModal',{attrs:{"title":_vm.opModalTitle,"color":_vm.opModalColor,"centered":true,"show":_vm.opModal},on:{"update:show":[function($event){_vm.opModal=$event},_vm.opModalConfirm]}},[_vm._v(" "+_vm._s(_vm.opModalText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }